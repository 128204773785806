import React  from "react"
import { useNavigate, useLocation } from "react-router-dom";
import { useAppState } from '../AppStateContext'
import { useState, useEffect, useRef } from "react";
import MainLayout from "./MainLayout"
import StoryPointCard from "./StoryPointCard"
import StoryPointRow from "./StoryPointRow"
import apiClient from "../api/Client";
import moment from "moment";
import * as amplitude from "@amplitude/analytics-browser";

export default function AccountSettings() {

  let {state, dispatch} = useAppState();
  
  const navigate = useNavigate();
  const location = useLocation();

  const [ paymentInfo, setPaymentInfo ] = useState({customer:{}, payment:{}, subscription:{cancel_at_period_end: false}});
  
  useEffect(() => {
      apiClient.get("payment/user/info").then((response) => {
        setPaymentInfo(response.data)
      }).catch((error) => {});
  }, []);


  const backAction = (e) => {
    e.preventDefault();
    amplitude.track("account-settings-back-action");
    navigate("/dashboard");
  }
  
  const pricePlanAction = (e) => {
    e.preventDefault();
    amplitude.track("account-settings-price-plan-action");
    navigate("/pricingPlan");
  }

return (
<MainLayout>
  <div className="centered-form-container">
    <div className="estimation-form-container">
      <div className="container">

        <div className="row pb-2">
          <div className="col-2">
            <button type="button" className="btn btn-outline-primary btn-rounded" onClick={backAction}>
              Back
            </button>
          </div>
          <div className="col-10">
          </div>
        </div>


        <div className="row">
          <p className="text-left text-move-left-on-mobile fw-bold fs-5 ">Account Settings:</p>
        </div>

        <div className="row">
          <p className="text-left text-move-left-on-mobile fs-6 ">Account ID: {state.user?.id}</p>
        </div>



        <div className="row mt-4">
          <div className="col">
              <div className="card">
                  <div className="card-header">
                    <h4 className="my-0 font-weight-normal text-left">Basic Info</h4>
                  </div>
                  <div className="card-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">Name: {paymentInfo.customer?.name || paymentInfo.payment?.billing_details?.name || state.user?.username}</li>
                      <li className="list-group-item">City: {paymentInfo.customer?.address || paymentInfo.payment?.billing_details?.address?.city}</li>
                      <li className="list-group-item">Phone: {paymentInfo.customer?.phone || paymentInfo.payment?.billing_details?.phone}</li>
                      <li className="list-group-item">Country: {paymentInfo.payment?.billing_details?.address?.country}</li>
                      <li className="list-group-item">State: {paymentInfo.payment?.billing_details?.address?.state}</li>
                      <li className="list-group-item">Postal Code: {paymentInfo.payment?.billing_details?.address?.postal_code}</li>
                      <li className="list-group-item">Currency: {paymentInfo.customer?.currency}</li>
                    </ul>
                  </div>
              </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
              <div className="card">
                  <div className="card-header">
                    <h4 className="my-0 font-weight-normal text-left">Security</h4>
                  </div>
                  <div className="card-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">Password: Google auth</li>
                    </ul>
                  </div>
                  <div className="text-end mb-4 px-4">
                    <button type="button" className="btn btn-lg btn-block btn-primary" disabled>Update</button>
                  </div>
              </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
              <div className="card">
                  <div className="card-header">
                    <h4 className="my-0 font-weight-normal text-left">Your Plan</h4>
                  </div>
                  <div className="card-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">Plan: {state.user?.price_plan}</li>
                      {paymentInfo.subscription.status && ( <>
                        <li className="list-group-item">Subscription Status: {paymentInfo.subscription?.status}</li>
                        <li className="list-group-item">Subscription Perion Start: {moment.unix(paymentInfo.subscription?.current_period_start).format('DD/MM/YYYY')}</li>
                        <li className="list-group-item">Subscription Perion End: {moment.unix(paymentInfo.subscription?.current_period_end).format('DD/MM/YYYY')}</li>
                      </>)}

                      {paymentInfo.subscription.cancel_at_period_end === true && ( <>
                        <li className="list-group-item">Subscription Cancel At: {moment.unix(paymentInfo.subscription?.cancel_at).format('DD/MM/YYYY')}</li>
                      </>)}

                    </ul>
                  </div>
                  <div className="text-end mb-4 px-4">
                    <button type="button" className="btn btn-lg btn-block btn-primary" onClick={pricePlanAction}>Change plan</button>
                  </div>
              </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
              <div className="card">
                  <div className="card-header">
                    <h4 className="my-0 font-weight-normal text-left">Billing</h4>
                  </div>
                  <div className="card-body">
                    <ul className="list-group list-group-flush">
                      { paymentInfo.payment.card ? (<>
                      <li className="list-group-item">Card ending: *{paymentInfo.payment?.card?.last4}</li>
                      <li className="list-group-item">Expires: {paymentInfo.payment?.card?.exp_month}/{paymentInfo.payment?.card?.exp_year}</li>
			
                      </>):(
                         <div className="my-0 text-left">not available</div>
                      )}
                    </ul>
                  </div>
                  <div className="text-end mb-4 px-4">
                    <button type="button" className="btn btn-lg btn-block btn-primary" disabled>Update</button>
                  </div>
              </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</MainLayout>

)
}
