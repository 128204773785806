import React, { useState } from "react"
import { useAppState } from '../AppStateContext'
import gigDevLogo from '../assets/images/01_gigDev_short_logo_color.png'; 

import MainLayout from "./MainLayout"

export default function Auth(props) {

  let {state, dispatch} = useAppState();

  const changeAuthMode = () => {
    dispatch({type:"action.authmode.changed",authMode : state.authMode === "signin" ? "signup" : "signin"});
  }

  if (state.authMode === "signin") {
    return (
     <MainLayout>
      <div className="Auth-form-container">

        <div className="Auth-form">
          <div className="Auth-form-content">
              <img src={gigDevLogo} className="img-fluid rounded"></img>
              <div className="mx-0 px-0 text-center">
                <h2 className="Auth-form-title">Your Project Management Assistant</h2>
              </div>

              <div className="mx-0 px-0 text-center">
                <h6>To access, please log in (right-top corner) using your Gmail account.</h6>
              </div>

          </div>


        </div>

      </div>
     </MainLayout>
    )
  }

  return (
    <div className="Auth-form-container">
      <form className="Auth-form">
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Sign In</h3>
          <div className="text-center">
            Already registered?{" "}
            <span className="link-primary" onClick={changeAuthMode}>
              Sign In
            </span>
          </div>
          <div className="form-group mt-3">
            <label>Full Name</label>
            <input
              type="email"
              className="form-control mt-1"
              placeholder="e.g Jane Doe"
            />
          </div>
          <div className="form-group mt-3">
            <label>Email address</label>
            <input
              type="email"
              className="form-control mt-1"
              placeholder="Email Address"
            />
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input
              type="password"
              className="form-control mt-1"
              placeholder="Password"
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
          <p className="text-center mt-2">
            Forgot <a href="#">password?</a>
          </p>
        </div>
      </form>
    </div>
  )
}
