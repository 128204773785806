import { useRef, useEffect } from 'react';
import jwt_decode from 'jwt-decode'
const GoogleSSO = () => {

    const g_sso = useRef(null);

    useEffect(() => {
        const google = window.google;

        if (g_sso.current) {
            /* global google */
            google.accounts.id.renderButton(g_sso.current, {
                theme: 'outline',
                size: 'large',
                type: 'standard',
                text: 'signin_with',
                shape: 'rectangular',
                logo_alignment: 'left',
                width: '100%',
            });
            google.accounts.id.prompt();
        }
    }, [g_sso.current]);


    return (<div ref={g_sso} />);
}

export default GoogleSSO;
