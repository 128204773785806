import React from 'react';

const ModalDialog = ({ children, show, onCancel, onOk, title,  okTitle = "Ok", cancelTitle = "Cancel", isInProgress = false}) => (
  <div
    className={`modal ${show ? ' modal-show' : ''}`}
    tabIndex="-1"
    role="dialog"
  >
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{title}</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCancel} disabled={isInProgress}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {children}
        </div>
        <div className="modal-footer">
          {okTitle && (
          <button type="button" className="btn btn-primary" onClick={onOk} disabled={isInProgress}>
            {okTitle}
          </button>
          )}
          <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={onCancel} disabled={isInProgress}>
             {cancelTitle}
          </button>
        </div>
      </div>
    </div>
  </div>
);
export default ModalDialog;