import React  from "react"
import { useState } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { useAppState } from '../AppStateContext'
import MainLayout from "./MainLayout"
import StoryPointCard from "./StoryPointCard"
import SubTaskRow from "./SubTaskRow"
import moment from "moment";
import * as amplitude from "@amplitude/analytics-browser";

export default function ItemDetails({job}) {

  let {state, dispatch} = useAppState();
  const [selectedSubTaskId, setSelectedSubTaskId] = useState("undefined");  

  const navigate = useNavigate();
  const location = useLocation();

  job = job || (state.jobs.find((jobItem) => jobItem.id === location.state.id)) || {descrition: "",type:[""]};

  if (!job.id) {
    return <Navigate to="/" />;
  }

  const unlockPremiumAction = (e) => {
    e.preventDefault();
    amplitude.track("item-details-unlock-premium-action");

    navigate("/pricingPlan",{state: {id: job.id}});
  }

  const copyToClipboardAction = (e) => {
    e.preventDefault();
    amplitude.track("item-details-copy-clipboard-action");
    navigator.clipboard.writeText(job.result[1]);
  }

  const backAction = (e) => {
    e.preventDefault();
    amplitude.track("item-details-back-action");
    navigate("/dashboard");
  }
  
  const itemSubTaskClickAction = (e) => {
    e.preventDefault();
    amplitude.track("item-details-sub-task-click-action");
    const navPath = e.currentTarget.getAttribute("navtargetpath");
    const id = e.currentTarget.getAttribute("id");
    //navigate(navPath, {state: {id: job.id, subTaskId: id}});
    setSelectedSubTaskId(id);
  }
  
  const manualEstimationAction = (e) => {
    e.preventDefault();
    amplitude.track("item-details-manual-estimation-action");
    navigate("/manualEstimation", {state: {id: job.id}});
  }

  const subTasks = job.subTasks.map((subTask, index) => {
    return {id: index , result:[subTask.sp], type: "Sub task", description: subTask.task, nav_path: "/subtaskDetail", color: "#3F52FF", textOpacity: 1.0, onDeleteClick: null};
  });

return (
<MainLayout>
  <div className="centered-form-container">
    <div className="estimation-form-container">
      <div className="container">

        <div className="row pb-2 px-0">
          <div className="col-2 px-0">
            <button type="button" className="btn btn-outline-primary btn-rounded" onClick={backAction}>
              Back
            </button>
          </div>
          <div className="col-10">
          </div>
        </div>

        <div className="row pb-2 px-0">
          <div class="d-flex justify-content-between px-0">
            <div className="text-start text-move-left-on-mobile fw-bold fs-5">{job.type}</div>
          </div>
        </div>

        <div className="row pt-2 pb-2 px-0">
          <div class="d-flex justify-content-between px-0">
            <div className="text-start fs-6 ">Task submitted on: {moment(job.created).fromNow()}</div>
          </div>
        </div>        

        <div className="row pt-2 px-0">
          <div className="container list-box-task">
            <div className="row">
              <div className="text-start fw-bold fs-5 ">Task description</div>
            </div>

            <div className="row">
              <div className="text-start fs-6 ">{job.description}</div>
              </div>
          </div>

        </div>

        <div className="row px-0">
          <p className="text-left text-move-left-on-mobile fw-bold fs-5 px-0">Estimation for this task was:</p>
        </div>

        <div className="row px-0">
          <div className="col-6 m-0 px-0">
          <p className="text-start text-move-left-on-mobile fs-6 ">Not happy with the outcome?</p>
          </div>
          <div className="col-6 m-0 px-0 text-end">
          <a href="#" onClick={manualEstimationAction} className="link-primary text-decoration-none">Update Estimation manually</a>
          </div>
        </div>

        <div className="row m-0 px-0">

          <div className="col-4 m-0 px-0 text-end">
            <span className="fs-6 ">final estimation</span>
          </div>
          <div className="col-2 m-0 px-0">
             <StoryPointCard sp={job.result[0]} imageSize={30} width={70}/>
          </div>


          <div className="col-5 m-0 px-0 text-end">
            <span className="fs-6 ">gigDev estimation</span>
          </div>

          <div className="col-1 my-auto">
            <div class="d-flex justify-content-between text-end">
               <StoryPointCard sp={job.result[5] ? job.result[5] : job.result[0]} color={"#FAFAFA"} textColor={"#3F52FF"} isLightMode={true} imageSize={20} width={50} height={30}/>
             </div>
          </div>

        </div>

        <div className="row mb-4">
        </div>

        <div className="row mb-0 form-description form-rounded estimation-description px-0">

          <div className="container px-0 m-3">
            <div className="row">

              <div className="col-2">
                <p className="text-left fw-bold fs-6 display-linebreak">Reasoning:</p>
              </div>

              <div className="col-8">
              </div>

              <div className="col-2 px-4 mb-0">
                <button type="button" className="btn btn-light" onClick={copyToClipboardAction}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-copy" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"></path>
                   </svg>
                </button>
              </div>

            </div>
          </div>

            <div className="form-outline mb-0 display-linebreak">
          	<p className="text-left fs-6">{job.result[1]}</p>
            </div>
        </div>

        <div className="row mb-0">
          
          <div className="col-4">
          <p className="text-left fs-6">{job.result[2]}</p>
          </div>

          <div className="col-4">
          <p className="text-left fs-6">{job.result[3]}</p>
          </div>
          
          <div className="col-4">
          <p className="text-left fs-6">{job.result[4]}</p>
          </div>

        </div>
       
        {subTasks.length > 0 && (<>
          <div className="row mt-0">
            <p className="text-left text-move-left-on-mobile fw-bold fs-4 px-0">Subtasks for this Task:</p>
          </div>

          
          <div className="row mb-2 px-0">
            <div className="container list-box">
              <SubTaskRow id={subTasks[0].id} key={subTasks[0].id} listItem={subTasks[0]} index={0} isFullView={selectedSubTaskId == subTasks[0].id} onShowClickCallback={itemSubTaskClickAction} />
              {state?.user.price_plan === "free_for_all" && (<p className="text-left fs-5 mt-3 px-0">Unlock premium to access more:</p>)}
              {subTasks.slice(1,50).map((listItem) => 
                <SubTaskRow id={listItem.id} key={listItem.id} listItem={listItem} index={listItem.id} isFullView={selectedSubTaskId == listItem.id} onShowClickCallback={itemSubTaskClickAction} isBlured={state?.user.price_plan === "free_for_all"}/>
              )}
            </div>
          </div>
        </>)}

        <div className="row m-0 px-0">
          
          <div className="col-8">
          </div>

          <div className="col-sm-4 m-0 px-0">
              {state?.user.price_plan === "free_for_all" && (
              <button type="submit" className="btn btn-primary estimation-button btn-rounded" onClick={unlockPremiumAction}>
                Unlock premium
              </button>
              )}
          </div>
          

        </div>
      </div>
    </div>
  </div>
</MainLayout>

)
}
