import React  from "react"
import { useNavigate, useLocation } from "react-router-dom";
import { useAppState } from '../AppStateContext'
import { useState, useEffect, useRef } from "react";
import MainLayout from "./MainLayout"
import StoryPointCard from "./StoryPointCard"
import StoryPointRow from "./StoryPointRow"
import apiClient from "../api/Client";
import moment from "moment";
import packageJson from '../../package.json';

export default function About() {

  let {state, dispatch} = useAppState();
  
  const navigate = useNavigate();
  const location = useLocation();

  const [ paymentInfo, setPaymentInfo ] = useState({customer:{}, payment:{}, subscription:{cancel_at_period_end: false}});
  
  useEffect(() => {
      apiClient.get("payment/user/info").then((response) => {
        setPaymentInfo(response.data)
      }).catch((error) => {});
  }, []);


  const backAction = (e) => {
    e.preventDefault();
    navigate("/dashboard");
  }
  
  const pricePlanAction = (e) => {
    e.preventDefault();
    navigate("/pricingPlan");
  }

return (
<MainLayout>
  <div className="centered-form-container">
    <div className="estimation-form-container">
      <div className="container">

        <div className="row">
          <p className="text-left text-move-left-on-mobile fw-bold fs-5 ">About:</p>
        </div>

        <div className="row">
          <p className="text-left text-move-left-on-mobile fs-6 ">Version: {packageJson.version}</p>
        </div>

        <div className="row pb-2">
            <button type="button" className="btn btn-outline-primary btn-rounded" onClick={backAction}>
              Back
            </button>
        </div>


      </div>
    </div>
  </div>
</MainLayout>

)
}
