import React  from "react"
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react"
import { useAppState } from '../AppStateContext'
import MainLayout from "./MainLayout"
import apiClient from "../api/Client";
import * as amplitude from "@amplitude/analytics-browser";


const successMessage = (backAction) => {
  return (
    <div className="success-msg">
      <svg width="3em" height="3em" viewBox="0 0 16 16" className="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
      </svg>
      <div className="title mb-2">Payment Successful</div>
      <button type="button" className="btn btn-outline-primary btn-rounded" onClick={backAction}>
        Back
      </button>
    </div>
  )
}
export default function PricingPlanCheckoutSessionSuccess() {

  let {state, dispatch} = useAppState();
  const navigate = useNavigate();
  const [ sessionId, setSessionId ] = useState("");
  
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
  
    if (query.get("checkout_session_id")) {
      setSessionId(query.get("checkout_session_id"));
      
      apiClient.get("payment/checkout-session/" + query.get("checkout_session_id")).then((response) => {
        console.log(response.data);
        //dispatch({type: "UPDATE_USER", payload: {user: response.data}});
      }).catch((error) => { console.log(error); });
    }
  
   
  }, []);


  const backAction = (e) => {
    e.preventDefault();
    amplitude.track("price-plan-checkout-session-success-back-action");
    navigate("/pricingPlan",{state: {isFromPaymentSuccess: true}});
  }

return (
<MainLayout>
<div className="centered-form-container">
    <div className="estimation-form-container">
      <div className="container">
        <div className="row m-0 text-center">
        {sessionId ? successMessage(backAction) : <React.Fragment>
          <div className="title mb-2">Checkout canceled</div>
          <button type="button" className="btn btn-primary btn-rounded" onClick={backAction}>
            Back
          </button>            
        </React.Fragment>
        }
         </div>

      </div>
    </div>
  </div>
</MainLayout>

)
}
