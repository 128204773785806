import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAppState } from '../AppStateContext'
import MainLayout from "./MainLayout"
import StoryPointListBox from "./StoryPointListBox"
import ModalDialog from "../components/ModalDialog";  
import validator from "validator";
import apiClient from "../api/Client";
import * as amplitude from "@amplitude/analytics-browser";
 
export default function TeamMembers() {
  
  let {state, dispatch} = useAppState();

  const [showConfigDialog, setShowConfigDialog] = useState(false);
  const [isLogging, setIsLogging] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [jobId, setJobId] = useState("");
  const [inputEmail, setInputEmail] = useState('')

  let navigate = useNavigate();


  const addMemberAction = (e) => {
    e.preventDefault();
    amplitude.track("team-members-add-member-action-dialog");
    setErrorMessage("")
    setShowConfigDialog(true);
  }

  const backAction = (e) => {
    e.preventDefault();
    navigate("/dashboard");
  }

  const showDeleteDialog = () => {
      setShowConfigDialog(true);
  };
  
  const cancelConfirmDialogAction = () => {
      setShowConfigDialog(false);
  };

  const okConfirmDialogAction = () => {

        if (!validator.isEmail(inputEmail))
        {
          setErrorMessage("Invalid email address. Please try ")
          return
        }
        (async () => {
          try {
            setIsLogging(true);
            const newMember = await apiClient.post(`members`,{email : inputEmail});
            dispatch({type:"action.members.add", member: newMember.data});
            setIsLogging(false);
            setShowConfigDialog(false);
 
          } catch(err) {
            setIsLogging(false);
            setErrorMessage("Error try again....")
          }
        })();
  };

  const itemShowClickAction = (e) => {
      e.preventDefault();
      const navPath = e.currentTarget.getAttribute("navtargetpath");
      const id = e.currentTarget.getAttribute("id");
      navigate(navPath, {state: {id: id}});
  }
  
  const itemDeleteClickAction = (e) => {
      e.preventDefault();
      const id = e.currentTarget.getAttribute("id");
      setJobId(id);
      showDeleteDialog();
  }    
  const validateEmail = (email) => {
   
  }
  const memeberList = state.members.map((member) => {
      return  {
          id: member.id,
          created: member.created,
          label: member.email[0].toUpperCase(),
          type: member.email,
          description: "",
          color: "#3F52FF",
          textOpacity: 1.0,
          subTasks:[]
      };
  });


return (
<MainLayout>
 <div className="centered-form-container">
    <div className="estimation-form-container">
      <div className="container">

        <div className="row pb-2">
          <div className="col-2 px-0">
            <button type="button" className="btn btn-outline-primary btn-rounded" onClick={backAction}>
              Back
            </button>
          </div>
          <div className="col-10">
          </div>
        </div>
        <div className="row">
          <p className="text-left fw-bold fs-1 px-0">Team Settings</p>
        </div>
        <div className="row">
          <p className="text-left fs-4 px-0">You are using {memeberList.length} out of 5 seats in your plan:</p>
        </div>

         {memeberList.length > 0 && ( 

        <div className="row mb-2">
          <div className="d-flex justify-content-between">
            <div className="fs-5 px-0">
              Team members:
            </div>
            <div className="fs-5">
            </div>
          </div>
        </div>
	 )}

        <div className="row mb-0">
         {memeberList.length > 0 && ( 
         <StoryPointListBox modelName={"members"} listItems={ memeberList.sort((a, b) => (b.created - a.created)) }/>
	 )}
        </div>
        <div className="row m-0 px-0">
          <div className="col-8">
          </div>

          <div className="col-sm-4 m-0 px-0">
              <button type="submit" className="btn btn-primary estimation-button btn-rounded" onClick={addMemberAction}>
                Add Team Member
              </button>
          </div>
          

        </div>


      </div>
  </div>
  </div>

  <ModalDialog title="Add Memeber" okTitle = "Add" cancelTitle="Cancel" show={showConfigDialog} onOk={okConfirmDialogAction} onCancel={cancelConfirmDialogAction}>
    { errorMessage ? ( <>
      <div className="alert alert-danger text-center" role="alert">
        {errorMessage}
        <button className="btn btn-primary btn-rounded" type="button" onClick={() => {setErrorMessage("")}}>Again</button>
      </div>
      </>
    ) : !isLogging ? (<>
	 <label htmlFor="set-input-email-id" className="form-label">Email address</label>
	 <input type="email" onChange={e => setInputEmail(e.target.value)} className="form-control" id="set-input-email-id" placeholder="enter email address..."/>
         </>
    ) : ( 
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-primary" role="status">
          </div>
          <span className="sr-only px-2">Adding member ...</span>
        </div>
    )}
  </ModalDialog>


</MainLayout>
)
}
