import React from 'react';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppState } from '../AppStateContext';
import gigDevLogo from '../assets/images/01_gigDev_short_logo_color.png'; 
import jwt_decode from 'jwt-decode';
import apiClient from "../api/Client";
import axios from 'axios';
import ReactRecaptcha3 from "react-google-recaptcha3";
import GoogleSSO from "../components/GoogleSSO"
import * as amplitude from "@amplitude/analytics-browser";

export default function NavBar({onError, onLoading}) {

  let {state, dispatch} = useAppState();
  const [isLogging, setIsLogging] = useState(false);
  const [isGoogleButton, setIsGoogleButton] = useState(false);
  const [userPicture, setUserPicture] = useState("");
  
  let navigate = useNavigate();
  const googleLoginHandler = (recaptchaResponseToken) => async (response, error) => {
    
    setIsLogging(true);
    onLoading(true);
    
    if(response.credential) {
        try {
            
            const credential_decoded = jwt_decode(response.credential);
            localStorage.setItem("user_picture", credential_decoded.picture);

            
            const loginResponse = await axios.post('/api/v1/auth/login', {google_jwt_token: response.credential, captchaToken: recaptchaResponseToken});
            
            localStorage.setItem("jwta", loginResponse.data.access_token);
            localStorage.setItem("jwtr", loginResponse.data.refresh_token);                      
            dispatch({type:"action.auth.signin.success",user: loginResponse.data.user});
            amplitude.track("action.auth.signin.success");
        } 
        catch (error) {
          onError("Login failed. Please try again.");
          amplitude.track("Login failed");
        }
        setIsLogging(false);
        onLoading(false);
      }  
      
  };

  const loginAction = (e) => {
    e.preventDefault();
    const google = window.google;
    ReactRecaptcha3.getToken().then((recaptchaResponseToken) => {
      google.accounts.id.initialize({
            client_id:"244222671177-b47unvfb6ggv756s7qkth4pghdtkgqm1.apps.googleusercontent.com",
            callback: googleLoginHandler(recaptchaResponseToken)
            });
            google.accounts.id.prompt((notification) => {
              
              if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
                  
                  if( notification.getNotDisplayedReason() === "opt_out_or_no_session")
                  {
                    setIsGoogleButton(true);
                  } 
                  else 
                  {
                    document.cookie =  `g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
                    google.accounts.id.prompt()
                  }
              }
          });
            
          });

  }


  const teamMembersAction = (e) => {
      e.preventDefault();
      navigate("/teamMembers");
  }

  const logoutAction = (e) => {
      e.preventDefault();
      dispatch({type: "action.auth.logout"});
  }


  const aboutAction = (e) => {
      e.preventDefault();
      navigate("/about");
  }

  const accountAction = (e) => {
      e.preventDefault();
      navigate("/accountSettings");
  }
  
  if (state?.user?.email && !userPicture) {
    setUserPicture(localStorage.getItem("user_picture"));
  } 

  return (
       <nav className="navbar fixed-top navbar-light bg-light ml-auto">

           <a className="navbar-brand" href="#">
             <img src={gigDevLogo}  width="50" height="50" className="d-inline-block" alt=""></img>
           </a>

          <ul className="navbar-nav me-auto d-none d-md-block">
            <li className="nav-item">
              <a className="nav-link active" href="#">Your Project Management Assistant</a>
            </li>
          </ul>

          {state?.user?.email ?

            <ul className="nav">
              <li className="nav-item pe-0">
                <a className="nav-link disabled text-truncate" href="#">{state.user.email}</a>
              </li>
              <li className="nav-item dropdown pe-3">
                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                     <img src={userPicture}  width="30" height="30" className="d-inline-block align-top btn-rounded" alt=""></img>
                </a>
                <ul className="dropdown-menu">
                  <li><a className="dropdown-item" href="#" onClick={accountAction}>Account</a></li>
                  {state.user?.price_plan == "enterprise" && (
                    <li><a className="dropdown-item" href="#" onClick={teamMembersAction}>Team</a></li>
		  )}
                  <li><a className="dropdown-item" href="#" onClick={logoutAction}>Logout</a></li>
                  <li><a className="dropdown-item" href="#" onClick={aboutAction}>About</a></li>
                </ul>
              </li>
            </ul>
            : 
            <div className="navbar-nav m-2 px-3">
                   {isLogging ? (
                      <button className="nav-item btn btn-primary btn-rounded" style={{"--bs-btn-padding-x": "1.0rem"}} type="submit" >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                          style={{ width: "1rem.", height: "1rem" }}
                        ></span>
                        <span className="sr-only m-3">in progress ...</span>
                      </button>
                    ) : (
                      <>  
                      { isGoogleButton ? (
                        <GoogleSSO/>
                        ) : (
                        <button className="nav-item btn btn-primary btn-rounded" style={{"--bs-btn-padding-x": "1.0rem"}} type="submit" onClick={loginAction}><i className="bi bi-arrow-right-square px-1"></i>Login</button>
                      )}
                      </>
                  )}

            </div>
          }

       </nav>
    )
}
