import React  from "react"
import { useAppState } from '../AppStateContext'
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import ReactRecaptcha3 from 'react-google-recaptcha3';
import MainLayout from "./MainLayout"
import apiClient from "../api/Client";
import * as amplitude from "@amplitude/analytics-browser";

export default function EstimateRate({job}) {
  let {state, dispatch} = useAppState();
  let navigate = useNavigate();
  const location = useLocation();

  job = job || (state.jobs.find((jobItem) => jobItem.id === location.state.id)) || {descrition: "",type:[""]};

  if (!job.id) {
    return <Navigate to="/" />;
  }

  const logoutAction = (e) => {
    e.preventDefault();
    amplitude.track("estimate-rate-logout-action");
    dispatch({type: "action.auth.logout"});
  }
  
  const shareRate = (rate, rateReason) => {

    ReactRecaptcha3.getToken().then((recaptchaResponseToken) => {
      
      apiClient.post('ai/shareRate', { 
        captchaToken: recaptchaResponseToken,
        estimationDescription:job.description,
        estimationStoryPoint: job.result[0],
        estimationFeedback: {...state.estimationFeedback, rateReason: rateReason, rate: rate, rateUserInputDescription: ""}
      }).then(response => {
  
              if (!response.ok) {
                return;
              }
  
              if (!response.data.ok) {
                  const error = (response.data && response.data.message) || response.status;
                  return Promise.reject(error);
              }
  
        }).catch(error => {
          console.error('Estimation Rate error:', error);
        });

     }).catch(error => {
       console.error('Estimation Rate error:', error);
   });
  }

  const upAction = (e) => {
    e.preventDefault();
    amplitude.track("estimate-rate-up-action");
    dispatch({type:"action.estimate.feedback.rate",value:1});
    shareRate(1, "");
    navigate("/estimate");
  }
  const downAction = (e) => {
    e.preventDefault();
    amplitude.track("estimate-rate-down-action");
    dispatch({type:"action.estimate.feedback.rate",value:0});
    navigate("/estimateWrongResult",{state: {id: job.id}});
  }

  const skipHereAction = (e) => {
    e.preventDefault();
    shareRate(0, "skip");
    amplitude.track("estimate-rate-skip-here-action");
    navigate("/estimate");
  }

return (
<MainLayout>
  <div className="centered-form-container">
    <div className="estimation-form-container">
      <div className="container">
        <div className="row m-0">
          <p className="text-center fw-bold fs-5">Rate your experience</p>
        </div>
        <div className="row m-0">
          <p className="text-center fs-6">Was the estimation helpful ? </p>
        </div>

        <div className="row mb-0">
          <div className="col text-center">
            <i className="thumb-rate bi bi-hand-thumbs-up" onClick={upAction}></i>
            <i className="thumb-rate bi bi-hand-thumbs-down"onClick={downAction}></i>
          </div>
        </div>

        <div className="row m-4">
          <div className="col--12">
          </div>
        </div>

        <div className="row m-4">
          <div className="col-sm-4">
          </div>

          <div className="col-sm-4 text-center ">
            <button type="submit" className="btn btn btn-outline-primary btn-rounded" onClick={skipHereAction}>
            Skip here
            </button>
          </div>
          
          <div className="col-4">
          </div>

        </div>
      </div>
    </div>
  </div>
</MainLayout>
)
}
