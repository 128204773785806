import { useState, createRef } from "react";
import { useNavigate, useLocation, Navigate} from "react-router-dom";
import { useAppState } from "../AppStateContext";
import ReactRecaptcha3 from "react-google-recaptcha3";
import MainLayout from "./MainLayout";
import lightBulp from "../assets/images/lightbulb.png";
import apiClient from "../api/Client";
import * as amplitude from "@amplitude/analytics-browser";

export default function EstimateModify({job}) {

  const [isTaskDescriptionValid, setTaskDescriptionValid] = useState(true);
  const [isEstimating, setIsEstimating] = useState(false);
  const [estimatingError, setEstimatingError] = useState("");
  const [sessionExpired, setSessionExpired] = useState(false);
  const [estimatingLimitError, setEstimatingLimitError] = useState("");
  
  let { state, dispatch } = useAppState();
  let navigate = useNavigate();

  const location = useLocation();

  job = job || (state.jobs.find((jobItem) => jobItem.id === location.state.id)) || {descrition: "",type:[""]};

  const [taskDescription, setTaskDescription] = useState(job.description);

  if (!job.id) {
    return <Navigate to="/" />;
  }

  const checkTaskDescriptionIsValid = (textForValidation) => {
    return textForValidation && (textForValidation.trim().split(/\s+/).length > 2);
  };

  const showErrorMessage = (message) => {
    setEstimatingError(message);
    setIsEstimating(false);
  };

  const logoutAction = (e) => {
    e.preventDefault();
    amplitude.track("estimate-modify-logout-action");
    dispatch({ type: "action.auth.logout" });
  };

  const estimateAction = (e) => {
    e.preventDefault();

    amplitude.track("estimate-modify-estimate-action");
    const taskDescriptionValidationResult = checkTaskDescriptionIsValid(taskDescription);
    
    setTaskDescriptionValid(taskDescriptionValidationResult);

    if (taskDescriptionValidationResult) {
      setIsEstimating(true);

      ReactRecaptcha3.getToken()
        .then((recaptchaResponseToken) => {
          apiClient.post("ai/estimation", { captchaToken: recaptchaResponseToken, estimationDescription: taskDescription}).then((response) => {
          
              if (!response.data) {
                showErrorMessage("Connection problem");
                return;
              }

              if (!response.data.ok) {
                const errorMessage = (response.data && response.data.message) || response.status;
                return Promise.reject({
                  message: errorMessage,
                  code: response.data.code,
                });
              }

              apiClient.put(`jobs/${job.id}`, {
                description: taskDescription, 
                type: ["Task estimation"],
                extended_data: response.data.result.subTasks,
                result: ["" + response.data.result.sp,
                  response.data.result.reason,  
                  response.data.result.technologies, 
                  response.data.result.dependencies,
                  response.data.result.variables
                ]
                }).then((response) => {

                dispatch({ type: "action.jobs.edit",job: {
                  id: response.data.id,
                  created: response.data.created,
                  result: response.data.result,
                  type: response.data.type[0],
                  description: response.data.description,
                  nav_path: "/showItem",
                  color: "#3F52FF",
                  textOpacity: 1.0,
                  subTasks: response.data.extended_data
                } 
                });

                dispatch({ type: "action.estimate.feedback.reset" });

                navigate("/estimateResult", {state: {id: response.data.id}});
                setIsEstimating(false);
                
              }).catch((error) => {
                setIsEstimating(false);
                showErrorMessage(error.message);
                console.error("Estimation error:", error);
              });


            })
            .catch((error) => {
              setIsEstimating(false);

              if (error.response && error.response.data && error.response.data.code === 429) {
                setEstimatingLimitError(error.response.data.error);
              } else if (error.code === 3) {
                setSessionExpired(true);
              } else {
                showErrorMessage(error.message);
                console.error("Estimation error:", error);
              }
            });
        })
        .catch((error) => {
          setIsEstimating(false);
          showErrorMessage(error.message);
          console.error("Estimation error:", error);
        });
    }
  };
  
  const handleTaskDescriptionInput = (e) => {
    e.preventDefault();
    setTaskDescription(e.target.value);
  };

  const backAction = (e) => {
    e.preventDefault();
    amplitude.track("estimate-modify-back-action");
    navigate("/newJob");
  }

  const unlockPremiumAction = (e) => {
    e.preventDefault();
    amplitude.track("estimate-modify-unlock-premium-action");
    navigate("/pricingPlan",{state: {id: job.id}});
  }

return (
<MainLayout>
  <div className="centered-form-container">
    <div className="estimation-form-container">
      <div className="container px-0">

        <div className="row pb-2 px-0">
          <div className="col-2 px-0">
            <button type="button" className="btn btn-outline-primary btn-rounded" onClick={backAction}>
              Back
            </button>
          </div>
          <div className="col-10">
          </div>
        </div>


        <div className="row px-0">
          <div class="d-flex justify-content-between px-0">
            <div className="text-start fw-bold fs-3">Modify Estimation</div>
          </div>
        </div>

        <div className="row mt-4 px-0">
          <div className="text-start fs-6 px-0">
            <img
              src={lightBulp}
              width="30"
              height="30"
              className=""
              alt=""
            ></img>
            Full description and Definition Of Done (DOD) will give the best
            result.
          </div>
        </div>

        <div className="row mt-4 px-0">
          <div class="d-flex justify-content-between px-0">
              <textarea                    
                onChange={handleTaskDescriptionInput}
                placeholder="Copypaste your task description into this input box and hit button."
                className={`form-control ${
                  isTaskDescriptionValid ? "" : "is-invalid"
                } estimation-description`}
                cols="5"
                rows="8"
              >{job.description}</textarea>
              <div className="invalid-feedback text-center">
                Please enter more words as task description
              </div>
            </div>
        </div>

        <div className="row mt-2 px-0">
              <div className="col-8 m-0 px-0">
              </div>
              <div className="col-md-4 m-0 px-0">
                {isEstimating ? (
                  <button
                    type="submit"
                    className="btn btn-secondary estimation-button btn-rounded"
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      style={{ width: "1rem.", height: "1rem" }}
                    ></span>
                    <span className="sr-only m-3">Estimating...</span>
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary estimation-button btn-rounded"
                    onClick={estimateAction}
                  >
                    <i className="bi bi-stars px-1"></i>Estimate
                  </button>
                )}
              </div>
        </div>

            {estimatingError && (
              <div className="row mt-3">
                <div className="alert alert-danger" role="alert">
                  {estimatingError}
                </div>
              </div>
            )}

            {estimatingLimitError && (
              <div className="row mt-3 px-0">
                <div className="alert alert-warning" role="alert">
                  {estimatingLimitError}
                </div>
                 <button type="submit" className="btn btn-primary estimation-button btn-rounded" onClick={unlockPremiumAction}>
                   Unlock premium
                 </button>
              </div>
            )}

          </div>
        </div>
      </div>
    </MainLayout>
  );
}
