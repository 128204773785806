import StoryPointCard from "./StoryPointCard"

export default function StoryPointRow({listItem, index, id, onShowClickCallback = function(){}, onDeleteClickCallback = null, isBlured = false, showCard = true}) {
  const colForDelete = onDeleteClickCallback ? "col-9 d-grid" : "col-10 d-grid";
return (
  <div className={`row mb-3 bg-white ${isBlured ? 'list-box-row-blured' : 'list-box-row'}`} >
    {showCard && <div className="col-2 d-grid mx-auto">

      {listItem.label ? (
	<div className="circle-label">{listItem.label}</div>
      ) : (
      <StoryPointCard id={id} navtargetpath={listItem.nav_path} sp={listItem?.result[0]} imageSize={15} color={listItem.color} colorBorder={listItem.colorBorder} onClick={onShowClickCallback}/>
      )}
    </div>}
    
    <div id={id} navtargetpath={listItem.nav_path} className={colForDelete}  style={{ opacity: listItem.textOpacity }} onClick={onShowClickCallback}>
      <p className="text-left m-0 px-4 px-sm-0 fw-bold text-responsive">{listItem.type}</p>
      <p className="text-left px-4 px-sm-0 text-truncate m-0 text-responsive" >{listItem.description}</p>
    </div>
    {onDeleteClickCallback && (
      <div  id={id} onClick={onDeleteClickCallback} className="col-1 px-0 d-grid" style={{ opacity: listItem.textOpacity }}>
      <button type="button" className="btn-close" disabled aria-label="Close"></button>
      </div>
    )}
  </div>
)
}
