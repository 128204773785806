import * as React from 'react'

const AppStateContext = React.createContext();

function appStateReducer(state, action) {

  switch (action.type) {
    case 'action.authmode.changed': {
      return {...state, authMode: action.authMode};
    }
    case 'action.auth.signin.success': {
      return {...state, user: action.user};
    }
    case 'action.auth.logout': {
      localStorage.removeItem("jwta");
      localStorage.removeItem("jwtr");
      localStorage.removeItem("user_picture");
      delete state.user;
      return {...state, jobs: [], jobsLoaded: false};
    }
    case 'action.jobs.reset': {
      return {...state, jobs: [], jobsLoaded: false};
    }
    case 'action.jobs.load': {
      return {...state, jobs: action.jobs, jobsLoaded: true};
    }
    case 'action.user.load': {
      return {...state, user: action.user};
    }
    case 'action.members.load': {
      return {...state, members: action.members};
    }
    case 'action.members.add': {
      return {...state, members: [...state.members, action.member]};
    }
    case 'action.members.remove': {
      return {...state, members: state.members.filter((member) => member.id !== action.id)};
    }
    case 'action.members.edit': {
      let members = state.members.filter((memeber) => memeber.id !== action.memeber.id);
      members.push(action.member);
      return {...state, members: members};
    }
    case 'action.jobs.remove': {
      return {...state, jobs: state.jobs.filter((job) => job.id !== action.id)};
    }    
    case 'action.jobs.edit': {
      let jobs = state.jobs.filter((job) => job.id !== action.job.id);
      jobs.push(action.job);
      return {...state, jobs: jobs}
    }
    case 'action.jobs.add': {
      return {...state, jobs: [...state.jobs, action.job]};
    }
    case 'action.estimate.feedback.reset': {
      return {...state, isValidEstimationDescription: false, estimationFeedback: {rate: 0, rateUserInputDescription: "", rateReason: ""}};
    }
    case 'action.estimate.feedback.rate': {
      return {...state, estimationFeedback: {...state.estimationFeedback, rate: action.value}};
    }
    case 'action.estimate.feedback.rateReason': {
      return {...state, estimationFeedback: {...state.estimationFeedback, rateReason: action.value}};
    }
    case 'action.estimate.feedback.rateUserInputDescription': {
      return {...state, estimationFeedback: {...state.estimationFeedback, rateUserInputDescription: action.value}};
    }
    case 'action.estimate.description.changed': {
      let isValidEstimationDescription = false;
      
      if (action.value && action.value.trim().split(/\s+/).length > 2)
        isValidEstimationDescription = true;
      
        return {...state, estimationDescription: action.value, isValidEstimationDescription: isValidEstimationDescription};
    }

    
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AppStateProvider({children}) {
  const [state, dispatch] = React.useReducer(appStateReducer, {
    authMode: "signin",
    estimationDescription : "",
    isValidEstimationDescription : false,
    estimationResult : {sp:0,technologies:"", dependencies:"", variables:""},
    estimationFeedback: {rate: 0, rateUserInputDescription: "", rateReason: ""},
    jobs: [],
    members: [],
    jobsLoaded: false,
    hasJWT: () => { return localStorage.getItem("jwta") && localStorage.getItem("jwtr") },
  })
  const value = {state, dispatch}
  return <AppStateContext.Provider value={value}>{children}</AppStateContext.Provider>
}

function useAppState() {
  const context = React.useContext(AppStateContext)
  if (context === undefined) {
    throw new Error('useAppState must be used within a CountProvider')
  }
  return context
}

export {AppStateProvider, AppStateContext, useAppState}
