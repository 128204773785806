import { useState, createRef } from "react"
import { useNavigate } from "react-router-dom";
import { useAppState } from '../AppStateContext'
import ReactRecaptcha3 from 'react-google-recaptcha3';
import MainLayout from "./MainLayout"
import lightBulp from '../assets/images/lightbulb.png'; 
import StoryPointCard from "./StoryPointCard"
import StoryPointListBox from "./StoryPointListBox"
import StoryPointRow from "./StoryPointRow"
import * as amplitude from "@amplitude/analytics-browser";
 
export default function NewJobType() {
  
  let {state, dispatch} = useAppState();
  let navigate = useNavigate();

  const backAction = (e) => {
    e.preventDefault();
    navigate("/dashboard");
  }

  const itemClickAction = (e) => {
    e.preventDefault();
    amplitude.track("new-job-type-item-click-action");
    const navPath = e.currentTarget.getAttribute("navtargetpath");
    navigate(navPath);
  }

  const jobsTypes = [
  {id: 1, result: [2], type: "Task Estimation", description: "Create a new estimation for your software development task", nav_path: "/estimate", color: "#3F52FF", colorBorder: "gray", textOpacity: 1.0, onClickShow: itemClickAction, onDeleteClick: null},
 // {id: 2, result: [2], type: "Task Verification", description: "Ensure that the task description includes all necessary details", nav_path: state?.user.price_plan === "free" ? "/pricingPlan" : "/verifyTask", color: "#3F52FF", colorBorder: "gray", textOpacity: 1.0, onClickShow: itemClickAction, onDeleteClick: null},
 // {id: 3, result: [2], type: "Divide into Subtasks", description: "Get key subtasks to ensure proper distribution of work", nav_path: state?.user.price_plan === "free" ? "/pricingPlan" : "/estimate", color: "#3F52FF", colorBorder: "gray", textOpacity: 1.0, onClickShow: itemClickAction, onDeleteClick: null},
  {id: 2, result: [2], type: "Task Verification", description: "Ensure that the task description includes all necessary details", nav_path: "/verifyTask", color: "#3F52FF", colorBorder: "gray", textOpacity: 1.0, onClickShow: itemClickAction, onDeleteClick: null},
  {id: 3, result: [2], type: "Divide into Subtasks", description: "Get key subtasks to ensure proper distribution of work", nav_path: "/estimate", color: "#3F52FF", colorBorder: "gray", textOpacity: 1.0, onClickShow: itemClickAction, onDeleteClick: null},
  {id: 4, result: [2], type: "Task Prioritization", description: "Choose optimal priorities for your tasks", nav_path: "/estimate" , color: "gray", colorBorder: "gray", textOpacity: 0.4, onClickShow: ()=>{}, onDeleteClick: null},
  {id: 5, result: [2], type: "Budget Projection", description: "Precisely plan the budget for your project", nav_path: "/estimate" , color: "gray", colorBorder: "gray", textOpacity: 0.4, onClickShow: ()=>{}, onDeleteClick: null},
  {id: 6, result: [2], type: "Scope Creep Prevention", description: "Analyze what might be lacking in your project", nav_path: "/estimate" , color: "gray", colorBorder: "gray", textOpacity: 0.4, onClickShow: ()=>{}, onDeleteClick: null},
  {id: 7, result: [2], type: "Identifying Technical Constraints", description: "Check your project for potential technical limitations" , color: "gray", colorBorder: "gray", textOpacity: 0.4, onClickShow: ()=>{}, onDeleteClick: null},
  {id: 8, result: [2], type: "Resource Allocation", description: "Select the best resource allocation for your project", nav_path: "/estimate" , color: "gray", colorBorder: "gray", textOpacity: 0.4, onClickShow: ()=>{}, onDeleteClick: null}
  ];



return (
<MainLayout>
 <div className="centered-form-container">
    <div className="estimation-form-container">
      <div className="container">

        {state.jobs.length > 0 &&

        <div className="row pb-2">
          <div className="col-2 px-0">
            <button type="button" className="btn btn-outline-primary btn-rounded" onClick={backAction}>
              Back
            </button>
          </div>
          <div className="col-10">
          </div>
        </div>
        }

        <div className="row">
          <p className="text-left fw-bold fs-1 px-0">What do you want to do today?</p>
        </div>
        <div className="row mb-2">
          <div className="d-flex justify-content-between px-0">
            <div className="fs-5 px-0">
              Select type of job in gigDev:
            </div>
            <div className="fs-5">
            </div>
          </div>
        </div>
        <div className="row mb-2">
          <div className="container list-box">
             {jobsTypes.slice(0,3).map((listItem, index) => 
               <StoryPointRow key={listItem.id} listItem={listItem} index={index} onShowClickCallback={listItem.onClickShow} showCard={false} />
             )}             
             <p className="text-left fs-5 mt-3 px-0">Available soon:</p>
             {jobsTypes.slice(3).map((listItem, index) => 
               <StoryPointRow key={listItem.id} listItem={listItem} index={index} onShowClickCallback={listItem.onClickShow} showCard={false}/>
             )}
          </div>
        </div>
      </div>
  </div>
  </div>
</MainLayout>
)
}
