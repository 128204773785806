import axios from 'axios'
import moment from 'moment'
import jwt_decode from 'jwt-decode'

const client = axios.create({
  baseURL: "/api/v1/"
})

client.interceptors.request.use(async (config) => {
  // Decode available tokens
  let jwta = {}
  let jwtr = {}
  if (window.localStorage.getItem('jwta') && window.localStorage.getItem('jwtr')) {
    jwta = jwt_decode(window.localStorage.getItem('jwta'))
    jwtr = jwt_decode(window.localStorage.getItem('jwtr'))
  }
  // Update tokens if the access token is expired and a valid refresh token is available
  if (jwta.exp < moment().unix() && jwtr.exp > moment().unix()) {
    const response = await axios.post(`/api/v1/auth/refreshToken`, {
      'refresh_token': window.localStorage.getItem('jwtr')
    })
    window.localStorage.setItem('jwta', response.data.access_token)
    window.localStorage.setItem('jwtr', response.data.refresh_token)
    jwta = jwt_decode(response.data.access_token)
  }
  // Add access token to request
  if (jwta.exp > moment().unix()) {
    config.headers['Authorization'] = `Bearer ${window.localStorage.getItem('jwta')}`
  }
  // Disconnect user if authentication tokens are expired
  // (Added to avoid unnecessary API calls)
  if (jwta.exp < moment().unix() && jwtr.exp < moment().unix()) {
    //window.localStorage.clear()
    //window.location = '/signin/'
  }
  return config
})


export default client



