import StoryPointCard from "./StoryPointCard"

export default function SubTaskRow({listItem, index, id, onShowClickCallback = function(){}, onDeleteClickCallback = null, isBlured = false, isFullView = false}) {
  const colForDelete = onDeleteClickCallback ? "col-9 d-grid" : "col-10  d-grid";

  let showClickCallback = onShowClickCallback

  if (isBlured) {
    showClickCallback = () => {}
  }

  const copyToClipboardAction = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(listItem.result[1]);
  }

return ( isFullView ? (
      <div className="container list-box-subtask mt-4">

        <div className="row">
              <div className="col-4">
              <p onClick={showClickCallback} className="text-left text-move-left-on-mobile fw-bold fs-5">Subtask title</p>
              </div>
              <div className="col-4">
              </div>
              <div className="col-4">
              <p onClick={showClickCallback} className="text-end  dropdown-toggle fw-bold fs-5"></p>
              </div>


        </div>

        <div className="row">
          <p className="text-left fs-6 ">{listItem.description}</p>
        </div>

        <div className="row">
          <p className="text-left text-move-left-on-mobile fw-bold fs-5 ">Estimation for this Subtask is:</p>
        </div>

        <div className="row m-0">

          <div className="col m-0 px-0">
             <StoryPointCard sp={listItem.result[0]} imageSize={30} width={70}/>
          </div>
        </div>

      </div>

  ) : (
  <div className={`row mb-3 bg-white ${isBlured ? 'list-box-row-blured' : 'list-box-row'}`} >
    <div className="col-2 d-grid mx-auto">
      <StoryPointCard id={id} navtargetpath={listItem.nav_path} sp={listItem.result[0]} imageSize={15} color={listItem.color} onClick={showClickCallback}/>
    </div>
    
    <div id={id} navtargetpath={listItem.nav_path} className={colForDelete}  style={{ opacity: listItem.textOpacity }} onClick={showClickCallback}>
      <p className="text-left m-0 px-4 px-sm-0 fw-bold text-responsive ">{listItem.type}</p>
      <p className="text-left px-4 px-sm-0 text-truncate m-0 text-responsive">{listItem.description}</p>
    </div>
    {onDeleteClickCallback && (
      <div  id={id} onClick={onDeleteClickCallback} className="col-1 px-0 d-grid" style={{ opacity: listItem.textOpacity }}>
      <button type="button" className="btn-close" disabled aria-label="Close"></button>
      </div>
    )}
  </div>
  )
)
}
