import React  from "react"
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { useAppState } from '../AppStateContext'
import MainLayout from "./MainLayout"
import SubTaskRow from "./SubTaskRow"
import StoryPointRow from "./StoryPointRow"
import * as amplitude from "@amplitude/analytics-browser";

export default function EstimateConfirmation({job}) {

  let {state, dispatch} = useAppState();
  
  const navigate = useNavigate();
  const location = useLocation();

  job = job || (state.jobs.find((jobItem) => jobItem.id === location.state.id)) || {descrition: "",type:[""]};

  if (!job.id) {
    return <Navigate to="/" />;
  }

  const estimateRateAction = (e) => {
    e.preventDefault();
    amplitude.track("estimate-confirmation-estimate-rate-action");
    navigate("/estimateRate",{state: {id: job.id}});
  }

  const unlockPremiumAction = (e) => {
    e.preventDefault();
    amplitude.track("estimate-confirmation-unlock-premium-action");
    navigate("/pricingPlan",{state: {id: job.id}});
  }


  const copyToClipboardAction = (e) => {
    e.preventDefault();
    amplitude.track("estimate-confirmation-copy-to-clipboard-action");
    navigator.clipboard.writeText(job.result[1]);
  }

  const backAction = (e) => {
    e.preventDefault();
    amplitude.track("estimate-confirmation-back-action");
    navigate("/dashboard");
  }

  const itemShowClickAction = (e) => {
      e.preventDefault();
      amplitude.track("estimate-confirmation-item-show-click-action");
      const navPath = e.currentTarget.getAttribute("navtargetpath");
      const id = e.currentTarget.getAttribute("id");
      navigate(navPath, {state: {id: job.id, subTaskId: id}});
  }


  
  const subTasks = job.subTasks.map((subTask, index) => {
    return {id: index , result:[subTask.sp], type: "Sub task", description: subTask.task, nav_path: "/estimateConfirmationFull", color: "#3F52FF", textOpacity: 1.0, onClickShow: itemShowClickAction, onDeleteClick: null};
  });
  

return (
<MainLayout>
  <div className="centered-form-container">
    <div className="estimation-form-container">
      <div className="container">

        <div className="row pb-2 px-0">
          <div className="col-2 px-0">
            <button type="button" className="btn btn-outline-primary btn-rounded" onClick={backAction}>
              Back
            </button>
          </div>
          <div className="col-10">
          </div>
        </div>

        <div className="row mt-4">
          <div class="d-flex justify-content-between px-0">
            <p className="text-start text-move-left-on-mobile fw-bold fs-5 ">Your Task:</p>
          </div>
        </div>

        <div className="row px-0">
          <div class="d-flex justify-content-between">
             <StoryPointRow key={job.id} id={job.id} listItem={job} index={0} />
           </div>
        </div>

        {subTasks.length > 0 && (<>

        <div className="row mt-4">
          <div class="d-flex justify-content-between px-0">
            <p className="text-start text-move-left-on-mobile fw-bold fs-5 ">Can be divided into subtasks:</p>
          </div>
        </div>
        

        <div className="row mt-0">
          <div class="d-flex justify-content-between px-0">
            <p className="text-left text-move-left-on-mobile fs-8 ">Subtasks for this job:</p>
          </div>
        </div>

        <div className="row px-0">
          <div className="container list-box  ">
             <SubTaskRow key={subTasks[0].id} listItem={subTasks[0]} index={0} id={0} onShowClickCallback={subTasks[0].onClickShow} />
             {state?.user.price_plan === "free_for_all" && (<p className="text-left fs-5 mt-3 px-0">Unlock premium to access more:</p>)}
             {subTasks.slice(1).map((listItem, index) => 
               <SubTaskRow key={listItem.id} listItem={listItem} index={index} id={index} onShowClickCallback={listItem.onClickShow} isBlured={state?.user.price_plan === "free_for_all" && false}/>
             )}
          </div>
        </div>
        </>)}

        <div className="row px-0">
          
        {state?.user.price_plan === "free_for_all" ? (<>
          <div className="col-6">
          </div>

          <div className="col-3">
              <button type="submit" className="btn btn-outline-primary estimation-button btn-rounded" onClick={estimateRateAction}>
                Skip for now
              </button>
          </div>
          
          <div className="col-sm-3 px-0">
              <button type="submit" className="btn btn-primary estimation-button btn-rounded" onClick={unlockPremiumAction}>
                Unlock premium
              </button>
          </div>
          </>) : (<>
            <div className="col-9">
            </div>

            <div className="col-sm-3 px-0">
              <button type="submit" className="btn btn-outline-primary estimation-button btn-rounded" onClick={estimateRateAction}>
              Leave Feedback
              </button>
          </div>

          </>
          )}


        </div>
      </div>
    </div>
  </div>
</MainLayout>

)
}
