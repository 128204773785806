import React  from "react"
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { useAppState } from '../AppStateContext'
import MainLayout from "./MainLayout"
import StoryPointCard from "./StoryPointCard"
import * as amplitude from "@amplitude/analytics-browser";

export default function EstimateResult({job}) {
  let {state, dispatch} = useAppState();
  let navigate = useNavigate();
  const location = useLocation();

  job = job || (state.jobs.find((jobItem) => jobItem.id === location.state.id)) || {descrition: "",type:[""]};
  
  if (!job.id) {
    return <Navigate to="/" />;
  }

  const pluralize = (count, noun, suffix = 's') => `${noun}${count !== 1 ? suffix : ''}`;

  const estimateRateAction = (e) => {
    e.preventDefault();
    amplitude.track("estimate-result-estimate-rate-action");
    navigate("/estimateConfirmation",{state: {id: job.id}});
  }

  const estimateModifyAction = (e) => {
    e.preventDefault();
    amplitude.track("estimate-result-estimate-modify-action");
    navigate("/estimateModify",{state: {id: job.id}});
  }

  
  const copyToClipboardAction = (e) => {
    e.preventDefault();
    amplitude.track("estimate-result-copy-to-clipboard-action");
    navigator.clipboard.writeText(job.result[1]);
  }

  const backAction = (e) => {
    e.preventDefault();
    amplitude.track("estimate-result-back-action");
    navigate("/estimate");
  }
  
  const manualEstimationAction = (e) => {
    e.preventDefault();
    amplitude.track("estimate-result-manual-estimation-action");
    navigate("/manualEstimation", {state: {id: job.id, backRouteNavPath: "/estimateResult"}});
  }

return (
<MainLayout>
  <div className="centered-form-container">
    <div className="estimation-form-container">
      <div className="container">

        <div className="row pb-2 px-0">
          <div className="col-2 px-0">
            <button type="button" className="btn btn-outline-primary btn-rounded" onClick={backAction}>
              Back
            </button>
          </div>
          <div className="col-10">
          </div>
        </div>

        <div className="row">
          <div class="d-flex justify-content-between px-0">
            <p className="text-left text-move-left-on-mobile fw-bold fs-5 ">Your task description:</p>
          </div>
        </div>

        <div className="row">
          <div class="d-flex justify-content-between px-0">
            <p className="text-left fs-6 ">{job.description}</p>
          </div>
        </div>

        <div className="row">
          <div class="d-flex justify-content-between px-0">
            <p className="text-left text-move-left-on-mobile fw-bold fs-5 ">Your Task Estimation is:</p>
          </div>
        </div>

        <div className="row px-0">
          <div className="col-6 m-0 px-0">
          <p className="text-start text-move-left-on-mobile fs-6 ">Not happy with the outcome?</p>
          </div>
          <div className="col-6 m-0 px-0 text-end">
          <a href="#" onClick={manualEstimationAction} className="link-primary text-decoration-none">Update Estimation manually</a>
          </div>
        </div>


        <div className="row m-0">

        <div className="col-4 m-0 px-0 text-end">
          <span className="fs-6 ">final estimation</span>
        </div>
        <div className="col-2 m-0 px-0">
          <StoryPointCard sp={job.result[0]} imageSize={30} width={70}/>
        </div>

        </div>

        <div className="row mb-4">
        </div>

        <div className="row mb-0 form-description form-rounded estimation-description ">

          <div className="container px-0 m-3">
            <div className="row">

              <div className="col-2">
                <p className="text-left fw-bold fs-6">Reasoning:</p>
              </div>

              <div className="col-8">
              </div>

              <div className="col-2 px-4 mb-0">
                <button type="button" className="btn btn-light" onClick={copyToClipboardAction}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-copy" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"></path>
                   </svg>
                </button>
              </div>

            </div>
          </div>

            <div className="form-outline mb-0 display-linebreak">
          	<p className="text-left fs-6">{job.result[1]}</p>
            </div>
        </div>

        <div className="row mb-0">
          
          <div className="col-4">
          <p className="text-left fs-6">{job.result[2]}</p>
          </div>

          <div className="col-4">
          <p className="text-left fs-6">{job.result[3]}</p>
          </div>
          
          <div className="col-4">
          <p className="text-left fs-6">{job.result[4]}</p>
          </div>

        </div>
       
        <div className="row ">
          
          <div className="col-4">
          </div>

          <div className="col-md-4 px-1">
              <button type="submit" className="btn btn-primary estimation-button btn-rounded" onClick={estimateModifyAction}>
                <i className="bi bi-stars px-1"></i> Modify Description
              </button>
          </div>

          <div className="col-md-4 px-0">
              <button type="submit" className="btn btn-primary estimation-button btn-rounded" onClick={estimateRateAction}>
                <i className="bi bi-stars px-0"></i> Confirm Estimation
              </button>
          </div>
          

        </div>
      </div>
    </div>
  </div>
</MainLayout>

)
}
