import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { useEffect, useRef } from 'react';
import { AppStateProvider, AppStateContext } from './AppStateContext'
import Auth from "./views/Auth"
import Dashboard from "./views/Dashboard"
import FullHistory from "./views/FullHistory"
import NewJobType from "./views/NewJobType"
import VerifyTask from "./views/VerifyTask"
import Estimate from "./views/Estimate"
import EstimateResult from "./views/EstimateResult"
import EstimateWrongResult from "./views/EstimateWrongResult"
import EstimateRate from "./views/EstimateRate"
import EstimateFeedback from "./views/EstimateFeedback"
import EstimateModify from "./views/EstimateModify"
import NotFound from "./views/NotFound"
import ReactRecaptcha3 from 'react-google-recaptcha3';
import ItemDetails from "./views/ItemDetails"
import EstimateConfirmation from "./views/EstimateConfirmation"
import EstimateConfirmationFull from "./views/EstimateConfirmationFull"
import AccountSettings from "./views/AccountSettings"
import About from "./views/About"
import PricingPlan from "./views/PricingPlan"
import PricingPlanCheckoutSessionSuccess from "./views/PricingPlanCheckoutSessionSuccess"
import TeamMembers from "./views/TeamMembers"
import ManualEstimation from "./views/ManualEstimation"
import * as amplitude from "@amplitude/analytics-browser";
import apiClient from "./api/Client";

function App() {
  useEffect(() => {
    amplitude.init(process.env.REACT_APP_AMPLITUDE_KEY);
    ReactRecaptcha3.init(process.env.REACT_APP_RECAPTCHA_SITE_KEY).then((status) => {});
    return () => {}
  }, [])


  return (
    <AppStateProvider>
      <BrowserRouter>
        <AppStateContext.Consumer>{({state, dispatch}) => (
              <Routes>
                <Route path="/" element={state.hasJWT() ? <Navigate to="/dashboard" /> : <Auth />} />
                <Route path="/dashboard" element={state.hasJWT() ? <Dashboard/> :<Navigate to="/"/>} />
                <Route path="/fullHistory" element={state.hasJWT() ? <FullHistory/> :<Navigate to="/"/>} />
                <Route path="/newJob" element={state.hasJWT() ? <NewJobType/> :<Navigate to="/"/>} />
                <Route path="/showItem" element={state.hasJWT() ? <ItemDetails/> :<Navigate to="/"/>} />
                <Route path="/estimate" element={state.hasJWT() ? <Estimate/> :<Navigate to="/"/>} />
                <Route path="/verifyTask" element={state.hasJWT() ? <VerifyTask/> :<Navigate to="/"/>} />
                <Route path="/estimateModify" element={state.hasJWT() ? <EstimateModify/> :<Navigate to="/"/>} />
                <Route path="/estimateResult" element={state.hasJWT() ? <EstimateResult/> :<Navigate to="/"/>} />
                <Route path="/estimateWrongResult" element={state.hasJWT() ? <EstimateWrongResult/> :<Navigate to="/"/>} />                                
                <Route path="/estimateRate" element={state.hasJWT() ? <EstimateRate/> :<Navigate to="/"/>} />
                <Route path="/estimateFeedback" element={state.hasJWT() ? <EstimateFeedback/> :<Navigate to="/"/>} />
                <Route path="/estimateConfirmation" element={state.hasJWT() ? <EstimateConfirmation/> :<Navigate to="/"/>} />
                <Route path="/estimateConfirmationFull" element={state.hasJWT() ? <EstimateConfirmationFull/> :<Navigate to="/"/>} />
                <Route path="/subtaskDetail" element={state.hasJWT() ? <EstimateConfirmationFull/> :<Navigate to="/"/>} />
                <Route path="/pricingPlan" element={state.hasJWT() ? <PricingPlan/> :<Navigate to="/"/>} />
                <Route path="/pricingPlanCheckoutSessionSuccess" element={state.hasJWT() ? <PricingPlanCheckoutSessionSuccess/> :<Navigate to="/"/>} />
                <Route path="/accountSettings" element={state.hasJWT() ? <AccountSettings/> :<Navigate to="/"/>} />
                <Route path="/teamMembers" element={state.hasJWT() ? <TeamMembers/> :<Navigate to="/"/>} />
                <Route path="/manualEstimation" element={state.hasJWT() ? <ManualEstimation/> :<Navigate to="/"/>} />
                <Route path="/about" element={state.hasJWT() ? <About/> :<Navigate to="/"/>} />
                                     
                <Route path="*" element={ <NotFound />} />
              </Routes>
          )}
        </AppStateContext.Consumer>
      </BrowserRouter>
    </AppStateProvider>
  )
}

export default App;
