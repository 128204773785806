import { useState, createRef } from "react"
import { useNavigate } from "react-router-dom";
import { useAppState } from '../AppStateContext'
import StoryPointRow from "./StoryPointRow"
import ModalDialog from "../components/ModalDialog";  
import apiClient from "../api/Client";
import * as amplitude from "@amplitude/analytics-browser";

export default function StoryPointListBox({listItems, onShowClick, onDeleteClick, modelName="jobs"}) {
  const [showConfigDialog, setShowConfigDialog] = useState(false);
  const [jobId, setJobId] = useState("");
  const [isLogging, setIsLogging] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  let {state, dispatch} = useAppState();
  let navigate = useNavigate();

    
  const showDeleteDialog = () => {
      amplitude.track("story-point-list-box-show-delete-dialog");
      setShowConfigDialog(true);
  };
  
  const cancelConfirmDialogAction = () => {
    amplitude.track("story-point-list-box-cancel-confirm-dialog-action");
    setShowConfigDialog(false);
  };

  const okConfirmDialogAction = () => {
      amplitude.track("story-point-list-box-ok-confirm-dialog-action");
      if(jobId) {
        (async () => {
          try {
            setIsLogging(true);
            await apiClient.delete(`${modelName}/${jobId}`);
            dispatch({type:`action.${modelName}.remove`, id: jobId});
            setIsLogging(false);
            setShowConfigDialog(false);
 
          } catch(err) {
            setIsLogging(false);
            setErrorMessage("Error try again....")
          }
        })();
      }
  };

  const itemShowClickAction = (e) => {
      e.preventDefault();
      amplitude.track("story-point-list-box-item-show-click-action");
      const navPath = e.currentTarget.getAttribute("navtargetpath");
      const id = e.currentTarget.getAttribute("id");

      if(navPath) {
         navigate(navPath, {state: {id: id}});
      }
  }
  
  const itemDeleteClickAction = (e) => {
      e.preventDefault();
      amplitude.track("story-point-list-box-item-delete-click-action");
      const id = e.currentTarget.getAttribute("id");
      setJobId(id);
      showDeleteDialog();
  }    

return (
<div className="container list-box">
  {listItems.map((listItem, index) => 
    <StoryPointRow key={listItem.id} id={listItem.id} listItem={listItem} index={index} onShowClickCallback={itemShowClickAction} onDeleteClickCallback={itemDeleteClickAction}/>
)}
  <ModalDialog title="Delete Job" isInProgress={isLogging} okTitle = "Yes" cancelTitle="No" show={showConfigDialog} onOk={okConfirmDialogAction} onCancel={cancelConfirmDialogAction}>
    { errorMessage ? ( 
      <div className="alert alert-danger" role="alert">
        {errorMessage}
      </div>
    ) : !isLogging ? ( 
      <p>Do you want to delete job ?</p>
    ) : ( 
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-primary" role="status">
          </div>
          <span className="sr-only px-2">Deleteing ...</span>
        </div>
    )}
  </ModalDialog>
</div>
)
}
