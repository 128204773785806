import {useState}  from "react"
import { useNavigate, useLocation, Navigate} from "react-router-dom";
import { useAppState } from '../AppStateContext'
import ReactRecaptcha3 from 'react-google-recaptcha3';
import MainLayout from "./MainLayout"
import apiClient from "../api/Client";
import * as amplitude from "@amplitude/analytics-browser";

export default function EstimateFeedback({job}) {
  let {state, dispatch} = useAppState();
  let navigate = useNavigate();
  const location = useLocation();
  const [feedbackDescription, setFeedbackDescription] = useState("");
  const [isFeedbackDescriptionValid, setFeedbackDescriptionValid] = useState(true);
  const [isSharingFeedback, setIsSharingFeedback] = useState(false);
  const [shareFeedbackError, setShareFeedbackError] = useState("");
  const [sessionExpired, setSessionExpired] = useState(false);

  job = job || (state.jobs.find((jobItem) => jobItem.id === location.state.id)) || {descrition: "",type:[""]};

  if (!job.id) {
    return <Navigate to="/" />;
  }
  

  const logoutAction = (e) => {
    e.preventDefault();
    amplitude.track("estimate-feedback-logout-action");
    dispatch({type: "action.auth.logout"});
  }

  const skipHereAction = (e) => {
    e.preventDefault();
    amplitude.track("estimate-feedback-skip-here-action");
    navigate("/estimate");
  }
  
  const showErrorMessage = (message) => {
    setShareFeedbackError(message);
    setIsSharingFeedback(false);
  }

  const shareAction = (e) => {
    e.preventDefault();
    
    amplitude.track("estimate-feedback-share-action");
    if (feedbackDescription && feedbackDescription.trim().split(/\s+/).length > 1)
    {
      setFeedbackDescriptionValid(true);

      setIsSharingFeedback(true);

      dispatch({type: "action.estimate.feedback.rateUserInputDescription", value: feedbackDescription});

      ReactRecaptcha3.getToken().then((recaptchaResponseToken) => {

        apiClient.post('ai/shareRate', { 
          captchaToken: recaptchaResponseToken,
          estimationDescription:job.description,
          estimationStoryPoint: job.result[0],
          estimationFeedback: {...state.estimationFeedback, rateUserInputDescription: feedbackDescription}
        }).then(response => {
                 if (!response.data.ok) {
                   showErrorMessage("Connection problem");
                   return;
                 }
     
                 if (!response.data.ok) {
                    const errorMessage = (response.data && response.data.message) || response.status;
                    return Promise.reject({message: errorMessage, code: response.data.code});
                 }
                 
                 navigate("/estimate");
         
                 setIsSharingFeedback(false);
     
           }).catch(error => {
            setIsSharingFeedback(false);

            if (error.code === 3) 
            {

              setSessionExpired(true);
            } 
            else 
            {
              showErrorMessage(error.message);
              console.error('Estimation feedback error:', error);
            }

           });

        }).catch(error => {
          setIsSharingFeedback(false);
          showErrorMessage(error.message);
          console.error('Estimation error:', error);
        });
    }
    else 
    {
      setFeedbackDescriptionValid(false);
    }

  }
  
  const handleUserInputDescriptionInput = (e) => {
    e.preventDefault();
    setFeedbackDescription(e.target.value);
  }

return (
<MainLayout>
  <div className="centered-form-container">
    <div className="estimation-form-container">
      <div className="container">
        <div className="row mb-0">
          <p className="text-left fw-bold fs-5">Your feedback helps us to improve!</p>
        </div>

        <div className="row mb-2">
          <p className="text-left fs-6">Is there anything else we might do better?</p>
        </div>

        <div className="row mb-4">
          <form>
            <div className="form-outline mb-1">
              <textarea onChange={handleUserInputDescriptionInput}  placeholder="Please share you thoughts here" className={`form-control ${isFeedbackDescriptionValid ? "" : "is-invalid"} estimation-description`} cols="5" rows="8">{feedbackDescription}</textarea>
              <div className="invalid-feedback text-center">Please enter more words</div>
            </div>

          </form>
        </div>
        
        <div className="row">
          
          <div className="col-2">
          </div>

          {!sessionExpired && !shareFeedbackError && (

          <div className="col-8">

          {isSharingFeedback ? (
            <button type="submit" className="btn btn-primary btn-rounded estimation-button" >
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{width: "1rem.", height: "1rem"}}></span>
            <span className="sr-only m-3">Sharing...</span>
            </button>
            ) : (
              <button type="submit" className="btn btn-primary btn-rounded estimation-button" onClick={shareAction}>
                <i className="bi bi-share"></i> Share
              </button>
            )}        
          </div>
            )}        
          
          <div className="col-2">
          </div>

        </div>

        {!sessionExpired && !shareFeedbackError && (

        <div className="row m-4">
          <div className="col-sm-4">
          </div>

          <div className="col-sm-4 text-center ">
            <button type="submit" className="btn btn btn-outline-primary btn-rounded" onClick={skipHereAction}>
            Skip here
            </button>
          </div>
          
          <div className="col-4">
          </div>

        </div>
        )}

        {sessionExpired && (
          <div className="row m-1">
            <div className="alert alert-secondary" role="alert">
                <p className="text-center"> Session expired please login again </p>
                <button type="submit" className="btn btn-primary estimation-button btn-rounded" onClick={logoutAction}>
                  Login again
                </button>
            </div>      
         </div>
       )}

        {shareFeedbackError && (
        <div className="row">
          <div className="alert alert-danger" role="alert">
          {shareFeedbackError}
          </div>      
        </div>
        )}      

      </div>
    </div>
  </div>
</MainLayout>
)
}
