import React  from "react"
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react"
import { useAppState } from '../AppStateContext'
import MainLayout from "./MainLayout"
import apiClient from "../api/Client";
import * as amplitude from "@amplitude/analytics-browser";

const CancelingMessage = ({accountAction}) => {
  return (
    <div className="success-msg">
      <svg width="3em" height="3em" viewBox="0 0 16 16" className="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
      </svg>
      <div className="title">Payment canceling successful and will take effect at end your subscription period.</div>
        <button type="button" className="mt-3 btn btn-outline-primary btn-rounded" onClick={accountAction}>
              More info about cancelation in Account Settings
        </button>
    </div>
  )
}


export default function PricingPlan({job}) {

  let {state, dispatch} = useAppState();
  const location = useLocation();

  const navigate = useNavigate();

  const isNavFromPaymentSuccess = location.state?.isFromPaymentSuccess === true;

  const [ isCanceling, setIsCanceling ] = useState(false);
  
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
  
    if (query.get("canceling")) {
      setIsCanceling(true);
    }
  }, []);


  const accountAction = (e) => {
      e.preventDefault();
      amplitude.track("price-plan-account-action");
      navigate("/accountSettings");
  }

  const backAction = (e) => {
    
    e.preventDefault();
    amplitude.track("price-plan-back-action");
    
    if(isNavFromPaymentSuccess === true)
    {
      navigate("/dashboard");
    }
    else
    {
      navigate(-1);
    }
  }

  const buyPlanProAction = (e) => {
    e.preventDefault();
    amplitude.track("price-plan-buy-plan-pro-action");
    apiClient.post("payment/create-checkout-session-plan-pro", {}).then((response) => {
      window.location.href = response.data.url;
    }).catch((error) => { console.log(error); });
  }

  const buyPlanFreeAction = (e) => {
    e.preventDefault();
    amplitude.track("price-plan-buy-plan-free-action");
    apiClient.post("payment/create-checkout-session-plan-free", {}).then((response) => {
      window.location.href = response.data.url;
    }).catch((error) => { console.log(error); });
  }

return (
<MainLayout>
<div className="centered-form-container">
      <div className="container">


        <div className="row pb-2">
          <div className="col-2">
            <button type="button" className="btn btn-outline-primary btn-rounded" onClick={backAction}>
              Back
            </button>
          </div>
          <div className="col-10">
          </div>
        </div>

        {isCanceling && (
          <div className="row p-4">
            <div className="col text-center">
              <CancelingMessage accountAction={accountAction}/>
            </div>
          </div>
        )}

        <div className="row price-plan-flex-card px-0">
          <div className="col-lg-4 col-sm-6">
              <div className="card">
                <div className="card-header">
                  <h4 className="my-0 font-weight-normal text-center">Free</h4>
                </div>
                <div className="card-body">
                <h1 className="card-title text-center">$0<small className="text-muted">/month</small></h1>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                      <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                    </svg> Story Point Estimation</li>
                    <li className="list-group-item"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                      <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                    </svg> Estimation Reasoning</li>
                    <li className="list-group-item"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                      <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                    </svg> Up to 10 Estimations Daily</li>
                  </ul>
              </div>
              <div className="text-center mb-4">
                    {state.user?.price_plan == "free" ? (
                    <button type="button" className="btn btn-lg btn-block btn-outline-primary">Current plan</button>
                    ):(
                      <button type="button"  onClick={buyPlanFreeAction} className="btn btn-lg btn-block btn-primary">Select Free plan</button>
                    )}
                  </div>
              </div>
          </div>
          <div className="col-lg-4 col-sm-6">
              <div className="card">
              <div className="card-header">
              <h4 className="my-0 font-weight-normal text-center">Pro</h4>
            </div>
            <div className="card-body">
              <h1 className="card-title text-center">$15<small className="text-muted">/month</small></h1>
              
              <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                    Everything in Basic, and:</li>
                    <li className="list-group-item"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                      <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                    </svg>Task Description Verification</li>
                    <li className="list-group-item"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                      <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                    </svg>Breaking Down Tasks Into Subtasks</li>
                    <li className="list-group-item"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                      <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                    </svg>Free Subtasks Estimation</li>
                    <li className="list-group-item"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                      <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                    </svg> Subtask Estimation Reasoning</li>
                    <li className="list-group-item"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                      <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                    </svg> Priority Access</li>
                    <li className="list-group-item"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                      <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                    </svg> Access to Beta Features</li>
                    <li className="list-group-item"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                      <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                    </svg>Up to 50 Estimations Daily</li>
                  </ul>
              </div>
              <div className="text-center mb-4">
              {state.user?.price_plan == "pro" ? (
               <button type="button" className="btn btn-lg btn-block btn-outline-primary" disabled>Current plan</button>
               ):(
               <button onClick={buyPlanProAction} type="submit" className="btn btn-lg btn-block btn-primary">Select Pro Plan</button>
               )}
               </div>
              </div>
          </div>
          <div className="col-lg-4 col-sm-6">
              <div className="card ">
                <div className="card-header">
                  <h4 className="my-0 font-weight-normal text-center">Enterprise</h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title text-center">$10 <small className="text-muted">/month per user</small></h1>
                  <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                          Everything in Pro, and:</li>
                        <li className="list-group-item"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                          <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                        </svg> Custom AI Model Based on Your Data</li>
                        <li className="list-group-item"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                          <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                        </svg> Longer Inputs</li>
                        <li className="list-group-item"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                          <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                        </svg> Admin Console</li>
                        <li className="list-group-item"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                          <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                        </svg> SSO and Domain Verification</li>
                      </ul>
                  </div>
                  <div className="text-center mb-4">
                    <a href="mailto:lukasz.lawrocki@gigdev.ai?subject=Enterprise Subscription Inquiry">
                    <button type="button" className="btn btn-lg btn-block btn-primary text-center mt-5">Contact Sales</button>
                    </a>
                  </div>

              </div>
          </div>
      </div>
    </div>
  </div>
</MainLayout>
)
}
