import { useNavigate } from "react-router-dom";
import { useAppState } from '../AppStateContext'
import MainLayout from "./MainLayout"
import StoryPointListBox from "./StoryPointListBox"
 
export default function FullHistory() {
  
  let {state, dispatch} = useAppState();
  let navigate = useNavigate();

  const backAction = (e) => {
    e.preventDefault();
    navigate("/dashboard");
  }

return (
<MainLayout>
 <div className="centered-form-container">
    <div className="estimation-form-container">
      <div className="container">

        <div className="row pb-2">
          <div className="col-2 px-0">
            <button type="button" className="btn btn-outline-primary btn-rounded" onClick={backAction}>
              Back
            </button>
          </div>
          <div className="col-10">
          </div>
        </div>
        <div className="row">
          <p className="text-left fw-bold fs-1 px-0">Job History</p>
        </div>
        <div className="row mb-2">
          <div className="d-flex justify-content-between px-0">
            <div className="fs-5">
              Your recent jobs:
            </div>
            <div className="fs-5">
            </div>
          </div>
        </div>
        <div className="row mb-2">
          <StoryPointListBox listItems={ state.jobs.sort((a, b) => (b.created - a.created)) }/>
        </div>
      </div>
  </div>
  </div>
</MainLayout>
)
}
