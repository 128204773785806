import { useState, createRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAppState } from "../AppStateContext";
import ReactRecaptcha3 from "react-google-recaptcha3";
import MainLayout from "./MainLayout";
import apiClient from "../api/Client";
import * as amplitude from "@amplitude/analytics-browser";

export default function VerifyTask() {
  const [isTaskDescriptionValid, setTaskDescriptionValid] = useState(true);
  const [isVerifing, setIsVerifing] = useState(false);
  const [verificationError, setVerificationError] = useState("");
  const [verificationLimitError, setVerificationLimitError] = useState("");
  const [verificationResult, setVerificationResult] = useState("");

  const [sessionExpired, setSessionExpired] = useState(false);

  let { state, dispatch } = useAppState();
  let navigate = useNavigate();

  const showErrorMessage = (message) => {
    setVerificationError(message);
    setIsVerifing(false);
  };

  const logoutAction = (e) => {
    e.preventDefault();
    dispatch({ type: "action.auth.logout" });
  };

  const estimateAction = (e) => {
    e.preventDefault();
    amplitude.track("verify-task-estimation-action");

    setTaskDescriptionValid(state.isValidEstimationDescription);

    if (state.isValidEstimationDescription) {
      setIsVerifing(true);

      ReactRecaptcha3.getToken()
        .then((recaptchaResponseToken) => {
          apiClient.post("ai/taskVerification", { captchaToken: recaptchaResponseToken, taskDescription: state.estimationDescription}).then((response) => {
          
              if (!response.data) {
                showErrorMessage("Connection problem");
                return;
              }

              if (!response.data.ok) {
                const errorMessage = (response.data && response.data.message) || response.status;
                return Promise.reject({
                  message: errorMessage,
                  code: response.data.code,
                });
              }
              setVerificationResult(response.data.result.feedback);
              setIsVerifing(false);

            })
            .catch((error) => {
              setIsVerifing(false);

              if (error.response && error.response.data && error.response.data.code === 429) {
                setVerificationLimitError(error.response.data.error);
              } else if (error.code === 3) {
                setSessionExpired(true);
              } else {
                showErrorMessage(error.message);
                console.error("Estimation error:", error);
              }
            });
        })
        .catch((error) => {
          setIsVerifing(false);
          showErrorMessage(error.message);
          console.error("Estimation error:", error);
        });
    }
  };

  const handleTaskDescriptionInput = (e) => {
    e.preventDefault();
    dispatch({
      type: "action.estimate.description.changed",
      value: e.target.value,
    });
  };

  const backAction = (e) => {
    e.preventDefault();
    amplitude.track("verify-task-back-action");
    navigate("/newJob");
  }

  const unlockPremiumAction = (e) => {
    e.preventDefault();
    amplitude.track("verify-task-unlock-premium-action");
    navigate("/pricingPlan");
  }

  const copyToClipboardAction = (e) => {
    e.preventDefault();
    amplitude.track("verify-task-copy-to-clipboard-action");
    navigator.clipboard.writeText(verificationResult);
  }

return (
<MainLayout>
  <div className="centered-form-container">
    <div className="estimation-form-container">
      <div className="container p-0">

        <div className="row px-0">
          <div className="col-2">
            <button type="button" className="btn btn-outline-primary btn-rounded" onClick={backAction}>
              Back
            </button>
          </div>
          <div className="col-10">
          </div>
        </div>


            <div className="row mb-2">
              <p className="text-left fw-bold fs-3">Task Verification</p>
            </div>

            <div className="row mb-2">
              <form>
                <div className="form-outline mb-1">
                  <textarea
                    onChange={handleTaskDescriptionInput}
                    placeholder="Copypaste your task description into this input box and hit button."
                    className={`form-control ${
                      isTaskDescriptionValid ? "" : "is-invalid"
                    } estimation-description`}
                    cols="5"
                    rows="8"
                  ></textarea>
                  <div className="invalid-feedback text-center">
                    Please enter more words as task description
                  </div>
                </div>
              </form>
            </div>

            <div className="row mt-2">
                  <div className="col-8 m-0 px-0">
                  </div>
                  <div className="col-md-4 m-0">
                    {isVerifing ? (
                      <button
                        type="submit"
                        className="btn btn-secondary estimation-button btn-rounded"
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                          style={{ width: "1rem.", height: "1rem" }}
                        ></span>
                        <span className="sr-only m-3">Verifing...</span>
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary estimation-button btn-rounded"
                        onClick={estimateAction}
                      >
                        <i className="bi bi-stars px-1"></i> Verify
                      </button>
                    )}
                  </div>
            </div>
           
            {verificationResult && (
            <div className="row mb-4 ">
            <div className="form-control form-rounded estimation-description ">
              <div className="container px-0 m-3">
                <div className="row">
                  <div className="col-2">
                    <p className="text-left fw-bold fs-6">Feedback:</p>
                  </div>
                  <div className="col-8">
                  </div>
                  <div className="col-2 px-4 mb-0">
                    <button type="button" className="btn btn-light" onClick={copyToClipboardAction}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-copy" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"></path>
                      </svg>
                    </button>
                  </div>
                </div>
                </div>
             

                <div className="form-outline mb-0 display-linebreak">
                <p className="text-left fs-6">{verificationResult}</p>
                </div>
              </div>
              </div>
            )}


            {verificationError && (
              <div className="row mt-3">
                <div className="alert alert-danger" role="alert">
                  {verificationError}
                </div>
              </div>
            )}

            {verificationLimitError && (
              <div className="row mt-3">
                <div className="alert alert-warning" role="alert">
                  {verificationLimitError}
                </div>
                 <button type="submit" className="btn btn-primary estimation-button btn-rounded" onClick={unlockPremiumAction}>
                   Unlock premium
                 </button>
              </div>
            )}

          </div>
        </div>
      </div>
    </MainLayout>
  );
}
