import { useState }  from "react"
import { useAppState } from '../AppStateContext'
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import MainLayout from "./MainLayout"
import * as amplitude from "@amplitude/analytics-browser";

export default function EstimateWrongResult({job}) {
  let {state, dispatch} = useAppState();
  let navigate = useNavigate();
  const location = useLocation();

  job = job || (state.jobs.find((jobItem) => jobItem.id === location.state.id)) || {descrition: "",type:[""]};
  
  if (!job.id) {
    return <Navigate to="/" />;
  }
  
  const skipHereAction = (e) => {
    e.preventDefault();
    amplitude.track("estimation-wrong-result-skip-here-action");
    navigate("/estimate");
  }

  const reason1Action = (e) => {
    e.preventDefault();
    amplitude.track("estimation-wrong-reason-1-action");
    dispatch({type: "action.estimate.feedback.rateReason",value: "estiamtion"});
    navigate("/estimateFeedback", {state: {id: job.id}});
  }

  const reason2Action = (e) => {
    e.preventDefault();
    amplitude.track("estimation-wrong-reason-2-action");
    dispatch({type: "action.estimate.feedback.rateReason",value: "reasoning"});
    navigate("/estimateFeedback", {state: {id: job.id}});
  }

  const reason3Action = (e) => {
    e.preventDefault();
    amplitude.track("estimation-wrong-reason-3-action");
    dispatch({type: "action.estimate.feedback.rateReason",value: "experience"});
    navigate("/estimateFeedback", {state: {id: job.id}});
  }

return (
<MainLayout>
  <div className="centered-form-container">
    <div className="estimation-form-container">
      <div className="container">
        <div className="row m-4">
          <p className="text-center fw-bold fs-4">What went bad?</p>
        </div>

        <div className="row justify-content-center">
          
          <div className="col-sm-4">
            <button type="submit" className="btn btn-lg btn-outline-secondary btn-rounded estimation-button m-1" onClick={reason1Action}>
            Estimation
            </button>
          </div>

          <div className="col-sm-4">
            <button type="submit" className="btn btn-lg btn-outline-secondary btn-rounded estimation-button m-1" onClick={reason2Action}>
            Reasoning
            </button>
          </div>
          
          <div className="col-sm-4">
            <button type="submit" className="btn btn-lg btn-outline-secondary btn-rounded estimation-button m-1" onClick={reason3Action}>
            Experience overall
            </button>
          </div>

        </div>

        <div className="row m-5">
          <div className="col-sm-4">
          </div>

          <div className="col-sm-4 text-center ">
            <button type="submit" className="btn btn btn-outline-primary btn-rounded" onClick={skipHereAction}>
            Skip here
            </button>
          </div>
          
          <div className="col-4">
          </div>

        </div>

      </div>
    </div>
  </div>
</MainLayout>

)
}
