import { useState, useEffect, useRef } from "react";
import { useAppState } from '../AppStateContext';
import { useNavigate } from "react-router-dom";
import NavBar from "./NavBar"
import apiClient from "../api/Client";
export default function MainLayout({children}) {
    
    let {state, dispatch} = useAppState();
    const [isLogging, setIsLogging] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
   
    useEffect(() => {
      if(state.hasJWT() && !state.user) {
        (async () => {
          
          try {
            setIsLogging(true);
            const response = await apiClient.get("users/me");
            dispatch({type:"action.user.load",user: response.data});
	    const members = (await apiClient.get("members")).data;
            dispatch({type:"action.members.load",members: members});
          } catch (error) { 
            setErrorMessage("Error loading user. Please try again.");
          }
          setIsLogging(false);
        })();
      }
    }, [])    

    useEffect(() => {
        (async () => {
            if(state.user && !state.jobsLoaded && !isLogging) {
                try {
                    setIsLogging(true);
                    const jobs = await apiClient.get("jobs");
                    const newJobs = jobs.data.map((job) => {
                        return  {
                            id: job.id,
                            created: job.created,
                            result: job.result,
                            type: job.type[0],
                            description: job.description,
                            nav_path: "/showItem",
                            color: "#3F52FF",
                            textOpacity: 1.0,
                            subTasks: job.extended_data
                        };
                    });
                    dispatch({type:"action.jobs.load", jobs: newJobs});

                } catch (error) {
                    console.error(error);
                    setErrorMessage("Connection error. Please try again.");
                }
                setIsLogging(false);
            }
        })();
    }, [state])

    return isLogging || errorMessage ? (
      <div className="container-fluid">
        <NavBar onError={(errMsg) => setErrorMessage(errMsg)} onLoading={(enabled) => setIsLogging(enabled)}/>
        <div className="centered-form-container">
          <div className="container d-flex flex-column align-items-center justify-content-center">
            {isLogging && (
              <div className="row">
                <div
                  className="spinner-border text-primary"
                  role="status"
                ></div>
              </div>
            )}
            {isLogging && (
              <div className="row">
                <span className="sr-only">Loading...</span>
              </div>
            )}

            {errorMessage && (<>
              <div className="row">
                <div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>
              </div>

              <div className="row">
               <button className="btn btn-primary btn-rounded" type="button" onClick={() => {window.location.reload();}}>Refresh</button>
              </div>
              </>
            )}
          </div>
        </div>
      </div>
    ) : (
      <div className="container-fluid">
        <NavBar onError={(errMsg) => setErrorMessage(errMsg)} onLoading={(enabled) => setIsLogging(enabled)}/>
        {children}
      </div>
    );
}
