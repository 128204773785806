import React  from "react"
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { useAppState } from '../AppStateContext'
import { useState } from "react";
import { Perspective } from "@egjs/flicking-plugins";
import MainLayout from "./MainLayout"
import apiClient from "../api/Client";
import Flicking from "@egjs/react-flicking";
import StoryPointCard from "./StoryPointCard"

import "@egjs/react-flicking/dist/flicking.css";
// Or, if you have to support IE9
import "@egjs/react-flicking/dist/flicking-inline.css";
import * as amplitude from "@amplitude/analytics-browser";

export default function ManualEstimation({job}) {

  let {state, dispatch} = useAppState();
  const [isEstimating, setIsEstimating] = useState(false);
  const [errorMessage, showErrorMessage] = useState("");
  const [selectedStoryPoint, setSelectedStoryPoint] = useState("0");

  const plugins = [new Perspective({ rotate: 0.6 })];
  const navigate = useNavigate();
  const location = useLocation();

  job = job || (state.jobs.find((jobItem) => jobItem.id === location.state.id)) || {descrition: "",type:[""]};

  if (!job.id) {
    return <Navigate to="/" />;
  }

  const backRouteNavPath = location.state.backRouteNavPath || "/showItem";

  const unlockPremiumAction = (e) => {
    e.preventDefault();
    navigate("/pricingPlan",{state: {id: job.id}});
  }

  const copyToClipboardAction = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(job.result[1]);
  }

  const backAction = (e) => {
    e.preventDefault();
    navigate(backRouteNavPath, {state: {id: job.id}});
  }
  
  const onChanged = (e) => {
      const manualStoryPoint = e.currentTarget.currentPanel.element.getAttribute("keydata");
      setSelectedStoryPoint(manualStoryPoint);      
  }

  const estimateConfirmAction = (e) => {
    
    e.preventDefault();
    
    setIsEstimating(true);
    showErrorMessage("");

    amplitude.track("manual-estimation-estimation-confirmed");
    const gigDevsp = job.result[5] || job.result[0];

    apiClient.put(`jobs/${job.id}`, { result: [selectedStoryPoint , job.result[1], job.result[2], job.result[3], job.result[4], gigDevsp ]}).then((response) => {
      
      const updatedJob = {...job , result: response.data.result};
      
      dispatch({ type: "action.jobs.edit",job: updatedJob});

      navigate(backRouteNavPath, {state: {id: job.id}});

    }).catch((error) => {
          setIsEstimating(false);
          showErrorMessage(error.message);
          console.error("Estimation error:", error);
    });

  }
  
  
return (
<MainLayout>
  <div className="centered-form-container">
    <div className="estimation-form-container">
      <div className="container estimation-manual-viewport px-0">

        <div className="row pb-2">
          <div className="col-4 px-0">
          <button type="button" className="btn btn-outline-primary btn-rounded" onClick={backAction}>
              Back
            </button>
          </div>

          <div className="col-4 text-left px-0">
          </div>

          <div className="col-4 px-0">
          </div>

        </div>

        <div className="row">
          <p className="text-left fw-bold fs-5 px-0">Update Estimation manually</p>
        </div>

        <div className="row">
          <div className="container text-center my-3">
            <Flicking gap={20} inputType={["touch", "mouse"]} align="center" circular={true} onChanged={onChanged} plugins={plugins}>
              <div keydata="0" className="card-panel"><StoryPointCard sp={"0"} imageSize={30} width={130} height={120}/> </div>
              <div keydata="1/2" className="card-panel"><StoryPointCard sp={"1/2"} imageSize={30} width={130} height={120}/></div>
              <div keydata="1" className="card-panel"><StoryPointCard sp={"1"} imageSize={30} width={130} height={120}/></div>
              <div keydata="2" className="card-panel"><StoryPointCard sp={"2"} imageSize={30} width={130} height={120}/></div>
              <div keydata="3" className="card-panel"><StoryPointCard sp={"3"} imageSize={30} width={130} height={120}/></div>
              <div keydata="5" className="card-panel"><StoryPointCard sp={"5"} imageSize={30} width={130} height={120}/></div>
              <div keydata="8" className="card-panel"><StoryPointCard sp={"8"} imageSize={30} width={130} height={120}/></div>
              <div keydata="13" className="card-panel"><StoryPointCard sp={"13"} imageSize={30} width={130} height={120}/></div>
              <div keydata="20" className="card-panel "><StoryPointCard sp={"20"} imageSize={30} width={130} height={120}/></div>
              <div keydata="40" className="card-panel"><StoryPointCard sp={"40"} imageSize={30} width={130} height={120}/></div>
              <div keydata="100" className="card-panel"><StoryPointCard sp={"100"} imageSize={30} width={130} height={120}/></div>
            </Flicking>
          </div>
        </div>

        <div className="row">
          <div className="col m-0 p-1">


          {isEstimating ? (
            <button
              type="submit"
              className="btn btn-secondary estimation-button btn-rounded"
            >
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
                style={{ width: "1rem.", height: "1rem" }}
              ></span>
              <span className="sr-only m-3">Updating...</span>
            </button>
          ) : (
            <button
              type="submit"
              className="btn btn-primary estimation-button btn-rounded"
              onClick={estimateConfirmAction}
            >
              <i className="bi bi-stars px-1"></i> Confirm Estimation = {selectedStoryPoint}
            </button>
          )}

          </div>
        </div>

        {errorMessage && (
              <div className="row mt-3">
                <div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>
              </div>
          )}


      </div>
    </div>
  </div>
</MainLayout>

)
}
