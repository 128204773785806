import { useState, createRef } from "react"
import { useNavigate, Navigate } from "react-router-dom";
import { useAppState } from '../AppStateContext'
import ReactRecaptcha3 from 'react-google-recaptcha3';
import MainLayout from "./MainLayout"
import lightBulp from '../assets/images/lightbulb.png'; 
import StoryPointCard from "./StoryPointCard"
import StoryPointListBox from "./StoryPointListBox"
import apiClient from "../api/Client";
import * as amplitude from "@amplitude/analytics-browser";

export default function Dashboard() {
  
  let {state, dispatch} = useAppState();
  let navigate = useNavigate();

  const newJobAction = (e) => {
    e.preventDefault();
    amplitude.track("dashboard-new-job-action");
    navigate("/newJob");
  }

  const seeFullListAction = (e) => {
    e.preventDefault();
    amplitude.track("dashboard-see-full-list-action");
    navigate("/fullHistory");
  }

  if(state.jobsLoaded && state.jobs.length == 0) {
    return <Navigate to="/newJob" />;
  }

return (
<MainLayout>
  <div className="centered-form-container">
    <div className="estimation-form-container">
      <div className="container">
        <div className="row">
          <p className="text-left fw-bold fs-1 px-0">Welcome to gigDev!</p>
        </div>
        <div className="row mb-2">
          {state.jobs.length > 0 && (

          <div className="d-flex justify-content-between px-0">
            <div className="fs-5 px-0">
              Your recent jobs:
            </div>
            <div className="fs-5">
              <p><a href="#" onClick={seeFullListAction} className="link-primary text-decoration-none">See full list</a></p>
            </div>
          </div>
         )}
        </div>
        <div className="row mb-2">

            {state.jobs.length > 0
            ? <StoryPointListBox listItems={state.jobs.sort((a, b) => (b.created - a.created)).slice(0,3)} onDeleteClick/>
	    : <p className="text-left fs-2 px-0">No tasks ? go to create one !!</p>
            }
        </div>
        <div className="row">
          <div className="col-9">
          </div>
   	          <div className="col-sm-3 m-0 px-0">
                <button type="submit" className="btn btn-primary estimation-button btn-rounded" onClick={newJobAction}>
                  <i className="bi bi-stars px-1"></i> New Job
                </button>
              </div>
        </div>
      </div>
  </div>
  </div>
 </MainLayout>
)
}
