import React  from "react"
import MainLayout from "./MainLayout"
import gigDevWhiteLogo from '../assets/images/01_gigDev_short_logo_white_color.png'; 
import gigDevLogo from '../assets/images/01_gigDev_short_logo_color.png'; 
 
export default function StoryPointCard({sp, imageSize, width=60, height=50, isLightMode = false, textColor="#FFFFFF", color="#3F52FF" , colorBorder="#3F52FF"}) {
    return (
    <div className="container" style={{ background: color, borderRadius: 8, width: width, border: '1px solid ' + colorBorder}}>
     <div className="row">
      <div className="col px-1">
        <img src={isLightMode ? gigDevLogo : gigDevWhiteLogo} width={imageSize} height={imageSize} className="float-start" alt=""></img>
      </div>
    </div>

    <div className="row justify-content-between" style={{height: height,color: textColor}}>
      <div className="col m-auto">
        <p className="text-center fw-bold fs-5 m-0"  >{sp}</p>
      </div>
    </div>

     <div className="row">
       <div className="col px-1 text-right">
         <img src={isLightMode ? gigDevLogo : gigDevWhiteLogo}  width={imageSize} height={imageSize} className="float-end" alt=""></img>
       </div>
     </div>
   </div>
  )
}
